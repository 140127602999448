import { inject } from "mobx-react";
import { useEffect, useState } from "react";

import { ResponsiveImage } from "../components/ResponsiveImage.component";
import type { SrcSet } from "@inferno/renderer-shared-core";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { isWindowDefined, resizeWatcher } from "@inferno/renderer-shared-core";
import { MagicLink } from "../ui";
import "./StationLogo.style.scss";
import { RESPONSIVE_IMAGE, COMPONENT_BREAKPOINTS } from "../ui/constants";
import type { Store } from "@inferno/renderer-shared-core";
interface LogoImgProps {
  imgUrl: string;
  stationUrl?: string;
  alt: string;
  width: number;
  height: number;
}

interface StationLogoProps {
  store?: Store;
}

export const LogoImg = ({ imgUrl, stationUrl, alt, width, height }: LogoImgProps) => {
  // strip query string from imgUrl
  const [logo] = imgUrl.split("?");
  const srcset: SrcSet[] = logo
    ? [
        {
          descriptor: "1x",
          url: `${logo}?ops=gravity(%22center%22),contain(${width},${height})&quality=80`,
        },
        {
          descriptor: "2x",
          url: `${logo}?ops=gravity(%22center%22),contain(${width * 2},${height * 2})&quality=80`,
        },
      ]
    : [];
  const src = `${logo}?ops=gravity(%22center%22),contain(${width},${height})&quality=80`;
  return stationUrl ? (
    <MagicLink to={stationUrl || "/"} context="station_link" aria-label="Station Link">
      <ResponsiveImage
        src={src}
        srcset={srcset}
        alt={alt}
        initialWidth={width}
        initialHeight={height}
        placeholder={src}
        placeholderSrcset={srcset}
      />
    </MagicLink>
  ) : (
    <ResponsiveImage
      src={src}
      srcset={srcset}
      alt={alt}
      initialWidth={width}
      initialHeight={height}
      placeholder={src}
      placeholderSrcset={srcset}
    />
  );
};

export const StationLogo = inject("store")(({ store }: StationLogoProps) => {
  if (!store) {
    return null;
  }

  const { site, env } = store;
  let width = RESPONSIVE_IMAGE.stationLogoMobileWidth;
  let height = RESPONSIVE_IMAGE.stationLogoMobileHeight;

  const [isDesktop, setIsDesktop] = useState(false);

  const handleWindowResize = () => {
    if (window.innerWidth > COMPONENT_BREAKPOINTS.mobileBreakpoint) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  };
  if (isDesktop) {
    width = RESPONSIVE_IMAGE.stationLogoDesktopWidth;
    height = RESPONSIVE_IMAGE.stationLogoDesktopHeight;
  }

  useEffect(() => {
    if (isWindowDefined()) {
      handleWindowResize();
      resizeWatcher.onWidthChange.subscribe(handleWindowResize);
      return () => resizeWatcher.onWidthChange.unsubscribe(handleWindowResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!site.sections.design?.station_logo?.id || !site.sections.general) {
    return null;
  }
  const logoUrl = formatImage(site.sections.design?.station_logo?.id, env.IMAGE_HOST);
  const alt = `${site.sections.general?.name} - ${site.sections.general?.positioner}`;

  return (
    <figure className="component-station-logo">
      <LogoImg imgUrl={logoUrl} stationUrl="/" alt={alt} width={width} height={height} />
    </figure>
  );
});
