import type { TFunction } from "i18next";
import { inject, observer } from "mobx-react";
import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useLocation } from "react-router-dom";

import { SocialIcons } from "../icons/SocialIcons.component";
import type { Site } from "@ihr-radioedit/inferno-core";
import type {
  BroadcastFacilitiesFragment,
  BroadcastFacilitiesIntegrationFragment,
  SitesBroadcastFacility,
  SitesMenu,
} from "@ihr-radioedit/inferno-webapi";
import { formatImage } from "@ihr-radioedit/inferno-core";
import { getLocale } from "@ihr-radioedit/inferno-core";
import {
  ClickSection,
  getRouteRegistryRoutes,
  matchPathByRoutes,
  reverseRoute,
  socialConfigAbstract,
} from "@inferno/renderer-shared-core";
import type { Store } from "@inferno/renderer-shared-core";

import "./Footer.style.scss";
import { Container } from "../elements/Container.component";
import MagicLink from "../elements/MagicLink.component";

interface FooterProps {
  store?: Store;
  amp?: boolean;
}

interface LocalBrandProps {
  [key: string]: string;
}

interface ResolvedMenu extends SitesMenu {
  href?: string;
}

export const resolveLocalBrand = (menu: ResolvedMenu, localBrands: LocalBrandProps, site: Site): ResolvedMenu => {
  if (!menu.href && menu.ref) {
    if (menu.type === "brand") {
      menu.href = `/featured/${localBrands[menu.ref]}/`;
    } else if (menu.type === "page") {
      menu.href = reverseRoute(site, menu.ref);
    } else {
      menu.href = menu.ref;
    }
  }

  if (menu.children) {
    menu.children = menu.children.map(m => m && resolveLocalBrand(m, localBrands, site));
  }
  return menu;
};

export const resolveLocalFooter = (site: Site, localFooter?: SitesMenu[] | null) => {
  if (!localFooter) {
    return null;
  }
  const localBrands: LocalBrandProps = {};
  site.micrositeReferences.forEach(index => (localBrands[index._id] = index.slug));
  return localFooter.map(menu => resolveLocalBrand(menu, localBrands, site));
};

export const CopyRight = ({
  providerName,
  additionalCopyright,
}: {
  providerName: string | null;
  additionalCopyright?: string | null;
}) => {
  if (!providerName) {
    return null;
  }
  const copyrightJsx = additionalCopyright ? <div className="addtional-copyright">{additionalCopyright}</div> : null;
  return (
    <div className="site-ownership">
      <div>
        &copy; {new Date().getFullYear()} {providerName}
      </div>
      {copyrightJsx}
    </div>
  );
};

export const InspectionFile = ({ facility, t }: { facility: SitesBroadcastFacility; t: TFunction }) => {
  const inspectionFile =
    facility.broadcastCallLetters && facility.broadcastBand
      ? `https://publicfiles.fcc.gov/${facility.broadcastBand.toLowerCase()}-profile/${facility.broadcastCallLetters}/`
      : null;
  return inspectionFile && facility.broadcastCallLetters ? (
    <li className="public-inspection-file">
      <MagicLink to={`${inspectionFile}`} target="_blank">
        {facility.broadcastCallLetters.toUpperCase()} {t("footer-public")}
      </MagicLink>
    </li>
  ) : null;
};

export const Facility = ({ facility, t }: { facility: BroadcastFacilitiesFragment; t: TFunction }) =>
  facility.broadcastCallLetters ? (
    <li>
      <MagicLink
        to={
          facility.politicalLinkOverride ||
          `https://politicalfiles.iheartmedia.com/files/location/${facility.broadcastCallLetters.toUpperCase()}/`
        }
        target="_blank"
      >
        {facility.broadcastCallLetters.toUpperCase()} {t("footer-political")}
      </MagicLink>
    </li>
  ) : null;

export const SiteFile = ({
  broadcastFacilities,
  t,
}: {
  broadcastFacilities?: BroadcastFacilitiesIntegrationFragment | null;
  t: TFunction;
}) => {
  const facilitiesJsx = broadcastFacilities?.plugin?.broadcastFacilities?.map((facility, i) => (
    <Fragment key={i}>
      <InspectionFile facility={facility} t={t} />
      <Facility facility={facility} t={t} />
    </Fragment>
  ));
  return facilitiesJsx?.length ? <Fragment>{facilitiesJsx}</Fragment> : null;
};

export const Footer = inject("store")(
  observer(({ store, amp }: FooterProps) => {
    if (!store) {
      return null;
    }

    const { site, env, page } = store;
    const { sections, integration } = site;
    const { t } = useTranslation();
    const ownership = site.index?.ownership?.id || "";
    const iHeartApp = ownership !== "aloha trust";
    const newNetwork = socialConfigAbstract(sections.social);
    const resolvedLocalMenu = resolveLocalFooter(site, sections.navigation?.menu_footer);
    const localMenu = resolvedLocalMenu
      ? resolvedLocalMenu.map((menuItem, index: number) => (
          <li key={index}>
            <MagicLink key={index} to={menuItem.href || menuItem.ref || ""} target={menuItem.target || ""}>
              {menuItem.label}
            </MagicLink>
          </li>
        ))
      : null;
    const [locale] = getLocale(site).split("-");
    const assistanceFile = sections.contact?.public_file_assistance_link_override
      ? sections.contact?.public_file_assistance_link_override
      : `/assistance/${locale}/`;

    const ownershipTag = ownership?.includes("providers/") || false;
    const ownershipTranslation = iHeartApp ? "footer-iheart" : "footer-aloha";
    const ownershipFallback = ownership ? ownership.replace("providers/", "") : "";

    const { pathname } = useLocation();
    const footerClass = useMemo(() => {
      const routePair = matchPathByRoutes(getRouteRegistryRoutes(), pathname);
      return classNames("component-site-footer", {
        "page-loading": routePair ? false : !page.currentPage,
      });
    }, [pathname, page.currentPage]);

    return (
      <ClickSection.Provider value="footer">
        <footer className={footerClass}>
          <Container block={false}>
            <div className="footer-row footer-links">
              {sections.navigation?.menu_footer?.length ? <ul className="footer-station-links">{localMenu}</ul> : null}

              <ul className="footer-national-links">
                {sections.navigation?.contact_menu_switch ? (
                  <li>
                    <MagicLink to={reverseRoute(site, "contact") || ""}>{t("footer-contact")}</MagicLink>
                  </li>
                ) : null}
                {sections.navigation?.advertise_switch ? (
                  <li>
                    <MagicLink to={reverseRoute(site, "advertise") || ""}>
                      {t("footer-advertise", { station: `${sections.general?.name}` })}
                    </MagicLink>
                  </li>
                ) : null}
                {iHeartApp ? (
                  <Fragment>
                    <li>
                      <MagicLink to={`${env.NATIONAL_SITE_URL}/apps/`} target="_blank">
                        {t("footer-app")}
                      </MagicLink>
                    </li>
                    <li>
                      <MagicLink to={`${env.NATIONAL_SITE_URL}/podcast/`} target="_blank">
                        {t("footer-podcast")}
                      </MagicLink>
                    </li>
                  </Fragment>
                ) : null}
              </ul>

              {newNetwork ? (
                <div className="component-social-network-icons">
                  <SocialIcons networks={newNetwork} />
                </div>
              ) : null}
              {sections.general?.description ? <div className="description">{sections.general.description}</div> : null}

              <hr />

              <ul className="lower-footer-national-links">
                <li>
                  <MagicLink to="/sitemap/">{t("footer-sitemap")}</MagicLink>
                </li>
                {sections.navigation?.contest_nav_switch ? (
                  <li>
                    <MagicLink to={reverseRoute(site, "rules") || ""}>{t("footer-contest")}</MagicLink>
                  </li>
                ) : null}
                <li>
                  <MagicLink to="https://www.iheart.com/privacy/">{t("footer-privacy")}</MagicLink>
                </li>
                <li>
                  <MagicLink to="https://privacy.iheart.com/">
                    {t("your_privacy_choices")}
                    {env.YOURCHOICE_ICON && !amp ? (
                      <img
                        data-src={env.YOURCHOICE_ICON}
                        alt="Your privacy Logo"
                        className="lazyload"
                        width={13}
                        height={14}
                      />
                    ) : null}
                  </MagicLink>
                </li>
                <li>
                  <MagicLink to="https://www.iheart.com/terms/">{t("footer-terms")}</MagicLink>
                </li>
                <li>
                  <MagicLink to="https://www.iheart.com/adchoices/">
                    AdChoices
                    {env.AD_ICON && !amp ? (
                      <img data-src={env.AD_ICON} alt="AdChoices Logo" className="lazyload" width={13} height={14} />
                    ) : null}
                  </MagicLink>
                </li>
                <SiteFile t={t} broadcastFacilities={integration} />
                {sections.contact?.eeo_report_asset?.id ? (
                  <li>
                    <MagicLink
                      to={`${formatImage(sections.contact?.eeo_report_asset?.id, env.IMAGE_HOST)}?passthrough=1`}
                      target="_blank"
                    >
                      {t("footer-eeo")}
                    </MagicLink>
                  </li>
                ) : null}
                {integration?.plugin?.broadcastFacilities?.length ? (
                  <>
                    <li>
                      <MagicLink to={assistanceFile}>{t("footer-assistance")}</MagicLink>
                    </li>
                    <li>
                      <MagicLink to="/fcc-applications" target="_blank">
                        {t("fcc_applications")}
                      </MagicLink>
                    </li>
                  </>
                ) : null}
              </ul>
              {ownershipTag ? (
                <CopyRight
                  providerName={site.index?.ownership?.taxo?.source?.display_name || ownershipFallback}
                  additionalCopyright={sections.general?.additional_copyright || ""}
                />
              ) : (
                <CopyRight
                  providerName={t(ownershipTranslation)}
                  additionalCopyright={sections.general?.additional_copyright || ""}
                />
              )}
            </div>
          </Container>
        </footer>
      </ClickSection.Provider>
    );
  }),
);
