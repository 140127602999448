import loadable from "@loadable/component";
import classnames from "classnames";
import "./HeaderUser.style.scss";

const UserProfile = loadable(() => import("./UserProfile.component"), {
  ssr: false,
});

interface HeaderUserProps {
  showMenu: boolean;
  location?: string;
}

export const HeaderUser = (props: HeaderUserProps) => {
  const userClass = classnames("header-user", {
    "show-menu": props.showMenu,
  });

  return (
    <div className={userClass}>
      <UserProfile location="header" />
    </div>
  );
};
