import { SitesMenu } from "../chrome/Navigation.component";
import { Microsite, Site } from "@ihr-radioedit/inferno-core";
import { ILog } from "@ihr-radioedit/inferno-core";
import { reverseRoute, routeMatchPair } from "@inferno/renderer-shared-core";

const logger = ILog.logger("Menu");

export function resolveMenu(
  menu: SitesMenu,
  micrositeRoot = "/featured/",
  routePair: routeMatchPair | null,
  site?: Site | Microsite,
  brandIds?: { [key: string]: string },
) {
  if (!site || (site instanceof Site && menu.href)) {
    return menu;
  }

  if (menu.type === "brand" && menu.ref) {
    if (!brandIds?.[`/index${menu.ref}`]) {
      logger.warn("No brand found for id: ", menu.ref);
      return menu;
    }
    menu.href = `${micrositeRoot}${brandIds?.[`/index${menu.ref}`]}/`;
  } else if (menu.type === "page") {
    if (menu.ref) {
      menu.href = reverseRoute(site, menu.ref);
    }
    if (site instanceof Microsite && routePair?.route.name === menu.ref) {
      menu.active = "active";
    }
  } else if (menu.ref && !menu.href) {
    menu.href = menu.ref;
  }

  if (menu.children) {
    menu.children = menu.children.map(m => resolveMenu(m, micrositeRoot, null, site, brandIds));
  }

  return menu;
}
