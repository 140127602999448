export const TiktokIcon = () => (
  <svg
    tabIndex={-1}
    focusable="false"
    className="svg-icon icon-tiktok"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="primary-path"
      tabIndex={-1}
      d="M20.7501 6.91071C19.5331 6.84196 18.3823 6.33443 17.5108 5.48214C17.0478 5.03475 16.683 4.49596 16.4394 3.9C16.1913 3.30286 16.0697 2.66078 16.0822 2.01428H12.5822V15.0179C12.5822 17.2143 11.2751 18.5107 9.65367 18.5107C9.26383 18.5197 8.87615 18.4504 8.5136 18.3068C8.15104 18.1633 7.82097 17.9484 7.54296 17.675C7.2645 17.4005 7.04247 17.0741 6.88939 16.7143C6.73851 16.3534 6.66081 15.9661 6.66081 15.575C6.66081 15.1838 6.73851 14.7966 6.88939 14.4357C7.04247 14.0759 7.2645 13.7495 7.54296 13.475C7.82097 13.2016 8.15104 12.9867 8.5136 12.8432C8.87615 12.6996 9.26383 12.6303 9.65367 12.6393C9.97636 12.6414 10.2964 12.6981 10.6001 12.8071V9.23571C10.2892 9.169 9.97165 9.13784 9.65367 9.14285C8.38222 9.14285 7.13932 9.51988 6.08215 10.2263C5.02498 10.9326 4.20101 11.9366 3.71445 13.1113C3.22788 14.286 3.10058 15.5786 3.34862 16.8256C3.59667 18.0726 4.20893 19.2181 5.10799 20.1171C6.00704 21.0162 7.1525 21.6284 8.39952 21.8765C9.64654 22.1245 10.9391 21.9972 12.1138 21.5107C13.2884 21.0241 14.2925 20.2001 14.9988 19.1429C15.7052 18.0858 16.0822 16.8429 16.0822 15.5714V8.78571C17.4634 9.70742 19.0897 10.1927 20.7501 10.1786V6.91071Z"
      fill="#27292D"
    />
  </svg>
);
