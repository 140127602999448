import classnames from "classnames";
import { useState, useCallback } from "react";
import { CloseIcon } from "../components/icons/CloseIcon.component";
import { SearchIcon } from "../components/icons/SearchIcon.component";
import { SearchBox } from "../ui";
import "./HeaderSearch.style.scss";
import { inject, observer } from "mobx-react";

import { SearchIconWithoutCircle } from "../components/icons/SearchIconWithoutCircle.component";
import type { Store } from "@inferno/renderer-shared-core";
interface HeaderSearchProps {
  store?: Store;
  location: string;
}

export const HeaderSearch = inject("store")(
  observer((props: HeaderSearchProps) => {
    const [showSearch, setSearch] = useState(false);
    const labelText = showSearch ? "Close Search" : "Open Search";

    const searchClass = classnames("header-search", {
      open: showSearch,
    });

    const toggleSearch = useCallback(
      (toggledSearch = !showSearch) => {
        if (toggledSearch) {
          const page = props.store?.page.currentPage;
          if (page) {
            const pageName = page ? `${props.store?.microsite ? "microsite_" : ""}${page.name}` : "";
            let referrer = "";
            if (typeof window !== "undefined") {
              referrer = window.location.href;
            }

            props.store?.onAnalyticsAction.dispatch({
              sectionName: "header",
              pageName,
              context: "search_Icon",
              action: "click",
              url: "",
              referrer,
            });
          }
        }
        setSearch(toggledSearch);
      },
      [props.store?.microsite, props.store?.page.currentPage, props.store?.onAnalyticsAction, showSearch],
    );
    const searchIcon = props.location === "premiere-header" ? <SearchIconWithoutCircle /> : <SearchIcon />;

    return (
      <div className={searchClass}>
        <SearchBox location="header" />

        <button
          className="search-button search-toggle"
          aria-label={labelText}
          onClick={() => toggleSearch(!showSearch)}
        >
          {showSearch ? <CloseIcon /> : searchIcon}
        </button>
      </div>
    );
  }),
);
