export const YoutubeIcon = () => (
  <svg
    tabIndex={-1}
    focusable="false"
    className="svg-icon icon-youtube"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      tabIndex={-1}
      className="primary-path"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.9991 4.48941C21.9843 4.75294 22.761 5.52471 23.0263 6.50353C23.5188 8.29176 23.4999 12.0188 23.4999 12.0188C23.4999 12.0188 23.4999 15.7271 23.0263 17.5153C22.761 18.4941 21.9843 19.2659 20.9991 19.5294C19.1993 20 12 20 12 20C12 20 4.81959 20 3.00081 19.5106C2.01564 19.2471 1.23888 18.4753 0.973639 17.4965C0.5 15.7271 0.5 12 0.5 12C0.5 12 0.5 8.29176 0.973639 6.50353C1.23888 5.52471 2.03459 4.73412 3.00081 4.47059C4.80064 4 12 4 12 4C12 4 19.1993 4 20.9991 4.48941ZM15.6944 12L9.70756 15.4259V8.57412L15.6944 12Z"
      fill="#27292D"
    />
  </svg>
);
