export const SearchIconWithoutCircle = ({ width = 36, height = 40 }) => (
  <svg
    className="svg-icon icon-search-without-circle"
    tabIndex={-1}
    width={width}
    height={height}
    viewBox="0 0 40 40"
    focusable="false"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="primary-path"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.552 28.331a14.811 14.811 0 01-7.538 2.051C6.722 30.382 0 23.581 0 15.191S6.722 0 15.014 0c8.291 0 15.013 6.801 15.013 15.191 0 3.828-1.4 7.326-3.71 9.997l9.096 10.736a2.49 2.49 0 01-.265 3.482 2.42 2.42 0 01-3.441-.268L22.552 28.33zm1.618-13.14c0 5.117-4.1 9.265-9.157 9.265s-9.156-4.148-9.156-9.265c0-5.117 4.1-9.265 9.157-9.265s9.156 4.148 9.156 9.265z"
      fill="#fff"
    />
  </svg>
);
