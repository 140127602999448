import { Component } from "react";
import classnames from "classnames";
import { inject, observer } from "mobx-react";

import type { Store } from "@inferno/renderer-shared-core";
import { getPushDown, isStickyPushdown } from "@inferno/renderer-shared-core";
import "./TemplateComponent.style.scss";

interface TemplateProps {
  store?: Store;
  display?: string;
}
@inject("store")
@observer
export class TemplateComponent extends Component<TemplateProps> {
  constructor(props: TemplateProps) {
    super(props);
  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      if (this.props.display === "grid") {
        window.addEventListener("resize", this.appHeight);
        this.appHeight();
      }
    }
  }

  appHeight = () => {
    document.documentElement.style.setProperty("--template-grid-height", `${window.innerHeight}px`);
  };

  componentWillUnmount() {
    if (this.props.display === "grid") {
      window.removeEventListener("resize", this.appHeight);
    }
  }

  render() {
    const { store, display } = this.props;
    const pushdown = getPushDown(store?.page?.currentPage);
    const templateClasses = classnames("component-template", {
      "sticky-pushdown": isStickyPushdown(pushdown),
      "template-grid": display === "grid",
    });
    return <div className={templateClasses}>{this.props.children}</div>;
  }
}
