import { SocialNetworkData } from "@inferno/renderer-shared-core";

import MagicLink from "../elements/MagicLink.component";

import { FacebookIcon } from "./FacebookIcon.component";
import { InstagramIcon } from "./InstagramIcon.component";
import { SnapchatIcon } from "./SnapchatIcon.component";
import "./SocialIcons.style.scss";
import { TiktokIcon } from "./TiktokIcon.component";
import { XIcon } from "./XIcon.component";
import { YoutubeIcon } from "./YoutubeIcon.component";

interface SocialIconsProps {
  networks: SocialNetworkData[];
  customIcons?: { [provider: string]: JSX.Element };
}

interface SocialNetworkMap {
  [provider: string]: {
    url: (name: string) => string;
    icon: JSX.Element;
    name?: string;
  };
}

const networkInfo: SocialNetworkMap = {
  facebook: {
    url: name => `//www.facebook.com/${name}`,
    icon: <FacebookIcon />,
    name: "facebook",
  },
  twitter: {
    url: name => `//www.x.com/${name}`,
    icon: <XIcon />,
    name: "X",
  },
  instagram: {
    url: name => `//www.instagram.com/${name}`,
    icon: <InstagramIcon />,
    name: "instagram",
  },
  youtube: {
    url: name => `//www.youtube.com/${name}`,
    icon: <YoutubeIcon />,
    name: "youtube",
  },
  snapchat: {
    url: name => `//www.snapchat.com/add/${name}`,
    icon: <SnapchatIcon />,
    name: "snapchat",
  },
  tiktok: {
    url: name => `//www.tiktok.com/@${name}`,
    icon: <TiktokIcon />,
    name: "tiktok",
  },
};

export const SocialIcons = (props: SocialIconsProps) => (
  <ul className="component-social-icons">
    {props.networks.map((network, index) =>
      networkInfo[network.network] ? (
        <li className="icon-container" key={index}>
          <MagicLink
            to={networkInfo[network.network].url(network.username)}
            aria-label={`Visit us on ${networkInfo[network.network].name}`}
            target="_blank"
          >
            {props.customIcons?.[network.network] || networkInfo[network.network].icon}
          </MagicLink>
        </li>
      ) : null,
    )}
  </ul>
);
